import {
   createMeetingDtoSchema,
   intExtendedField,
   updateMeetingDtoSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const defaultPathParams = z.object({
   meetingId: intExtendedField,
});

export const zoom = c.router(
   {
      createMeeting: {
         method: 'POST',
         path: '/zoom/create-meeting',
         body: createMeetingDtoSchema,
         responses: {
            200: c.type<object>(),
         },
      },
      getMeeting: {
         method: 'GET',
         path: '/zoom/:meetingId',
         pathParams: defaultPathParams,
         responses: {
            200: c.type<object>(),
         },
      },
      patchMeeting: {
         method: 'PATCH',
         path: '/zoom/:meetingId',
         body: updateMeetingDtoSchema,
         pathParams: defaultPathParams,
         responses: {
            200: c.noBody(),
         },
      },
      deleteMeeting: {
         method: 'DELETE',
         path: '/zoom/:meetingId',
         pathParams: defaultPathParams,
         body: c.noBody(),
         responses: {
            200: c.type<object>(),
         },
      },
      listUsers: {
         method: 'GET',
         path: '/zoom/list-users',
         responses: {
            200: c.type<object>(),
         },
      },
      createUser: {
         method: 'POST',
         path: '/zoom/create-user',
         body: z.record(z.string(), z.unknown()),
         responses: {
            200: c.type<object>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
